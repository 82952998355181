<template>
  <div>
    <br>
    <div class="has-text-centered">
      <img src="/images/drag.png" alt="">
    </div>
    <div class="columns is-mobile has-text-centered">

      <div class="column">
        <draggable
          class="list"
          :list="list"
          :group="{ name: 'people' }"
          :sort="false"
        >
          <div
            class="list-group-item drag"
            v-for="(element) in list"
            :key="element.name"
          >
            <img :src="'/images/'+element.name" alt="">
          </div>
        </draggable>
      </div>
      <div class="column">

        <draggable
          class="list-group drop1"
          :list="list2"
          group="people"
          @change="change(list2, $event)"
        >
          <div
            class="drop "
            v-for="(element) in list2"
            :key="element.name"
          >
            <img :src="'/images/'+element.name" alt="">
          </div>
        </draggable>

        <draggable
          class="list-group drop2"
          :list="list3"
          group="people"
          @change="change(list3, $event)"
        >
          <div
            class="drop "
            v-for="(element) in list3"
            :key="element.name"
          >
            <img :src="'/images/'+element.name" alt="">
          </div>
        </draggable>

        <draggable
        class="list-group drop3"
        :list="list4"
        group="people"
        dragClass="drag"
        @change="change(list4, $event)"
        >
          <div
            class="drop"
            v-for="(element) in list4"
            :key="element.name"
          >
            <img :src="'/images/'+element.name" alt="">
          </div>
        </draggable>
        <draggable
          class="list-group drop4"
          :list="list5"
          group="people"
          @change="change(list5, $event)"
        >
          <div
            class="drop"
            v-for="(element) in list5"
            :key="element.name"
          >
            <img :src="'/images/'+element.name" alt="">
          </div>
        </draggable>
      </div>
    </div>
    <div class="has-text-centered">
      <transition name="fade">
        <button
          v-if="showError"
          class="btn"
          @click="reset()"
        >
          Skús znovu
        </button>
      </transition>
    </div>
    <transition name="roll">
      <div class="notification notification-yellow" v-if="showError">
        <button
          class="delete"
          @click="reset()">
        </button>
        Toto nie je správna kombinácia
        <button
          class="btn"
          @click="reset()"
        >
          Skús znova
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'DragButton',
  components: {
    draggable,
  },
  data() {
    return {
      showError: false,
      list: [
        { name: 'drag1.png', id: 1 },
        { name: 'drag2.png', id: 2 },
        { name: 'drag3.png', id: 3 },
        { name: 'drag4.png', id: 4 },
      ],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    reset() {
      this.list = [
        { name: 'drag1.png', id: 1 },
        { name: 'drag2.png', id: 2 },
        { name: 'drag3.png', id: 3 },
        { name: 'drag4.png', id: 4 },
      ];
      this.list2 = [];
      this.list3 = [];
      this.list4 = [];
      this.list5 = [];
      this.showError = false;
    },
    nextPage() {
      this.$emit('solved');
    },
    change(targetList) {
      if (targetList.length > 1) {
        this.list.push(targetList.pop());
      }
      if (this.list.length === 0) {
        if (this.list2[0].id === 3
        && this.list3[0].id === 1
        && this.list4[0].id === 2
        && this.list5[0].id === 4) {
          this.$emit('solved');
        } else {
          this.showError = true;
        }
      } else {
        this.showError = false;
      }
    },
  },
};
</script>
<style>
  .list {
    min-height: 400px;
  }
  .list-group {
    box-sizing: border-box;
    display: inline-block;
    border-radius: 10px;
    width: 110px;
    min-height: 120px;
    vertical-align: middle;
    margin-right: 20px;
    position: relative;
    text-align: center;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .drag, .drop {
    box-sizing: border-box;
    display: inline-block;
    border-radius: 10px;
    width: 110px;
    height:133px;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  .drag {
    color: #fff;
    cursor: move;
  }
  .drop {
    transform:scale(0.7) translateX(50px) translateY(70px) ;
  }
  .drop1 {
    background-image: url('/images/drop1.png');
  }
  .drop2 {
    background-image: url('/images/drop2.png');
  }
  .drop3 {
    background-image: url('/images/drop3.png');
  }
  .drop4 {
    background-image: url('/images/drop4.png');
  }
</style>
