<template>
  <div id="app">
    <router-view/>
    <cookie-law theme="base" buttonText="Súhlasím">
      <div slot="message">
        Táto stránka používa iba nevyhnutné cookies pre svoju prevádzku
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
  mounted() {
    this.$store.dispatch('setTasks');
  },
};
</script>
