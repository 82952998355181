<!-- eslint-disable max-len -->
<template>
  <div class="home has-text-homeblack is-family-secondary">
    <!-- <Navigation></Navigation> -->
    <section class="section">
      <div class="container content">
        <h1>Poučenie o právach dotknutých osôb</h1>
        <p>Prevádzkovateľ v súvislosti so spracúvaním osobných údajov na zabezpečenie spravodlivého a transparentného spracúvania informuje dotknuté osoby, že majú:</p>

        <ul>
          <li>právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcich sa jej osoby a práva na ich opravu alebo vymazanie alebo obmedzenie spracúvania,&nbsp;</li>
          <li>právo namietať proti spracúvaniu,&nbsp;</li>
          <li>právo na prenosnosť údajov,&nbsp;</li>
          <li>právo kedykoľvek svoj súhlas odvolať bez toho, aby to malo vplyv na zákonnosť spracúvania založeného na súhlase udelenom pred jeho odvolaním,&nbsp;</li>
          <li>právo podať sťažnosť dozornému orgánu,&nbsp;</li>
          <li>právo na informáciu o tom, či je poskytovanie osobných údajov zákonnou alebo zmluvnou požiadavkou, alebo požiadavkou, ktorá je potrebná na uzavretie zmluvy, či je dotknutá osoba povinná poskytnúť osobné údaje, ako aj možné následky neposkytnutia takýchto údajov,</li>
          <li>právo získať informáciu z&nbsp;akého zdroja pochádzajú jej osobné údaje, ak neboli získané od dotknutej osoby, prípadne informáciu o&nbsp;tom, či údaje podchádzajú z&nbsp;verejne prístupných zdrojov</li>
          <li>právo získať od prevádzkovateľa potvrdenie o&nbsp;tom, či sa spracúvajú osobné údaje, ktoré sa jej týkajú a&nbsp;ak tomu tak je, má právo získať prístup k&nbsp;týmto osobným údajov a&nbsp;tieto informácie:</li>
        </ul>

        <p>&nbsp; &nbsp; &nbsp;a) účel spracúvania;</p>

        <p>b) &nbsp; &nbsp;kategórie dotknutých osobných údajov;</p>

        <p>c) príjemcovia alebo kategórie príjemcov, ktorým boli alebo budú osobné údaje poskytnuté, najmä príjemcovia v tretích krajinách alebo medzinárodné organizácie;&nbsp;</p>

        <p>d) ak je to možné, predpokladaná doba uchovávania osobných údajov alebo, ak to nie je možné, kritériá na jej určenie;&nbsp;</p>

        <p>e) existencia práva požadovať od prevádzkovateľa opravu osobných údajov týkajúcich sa dotknutej osoby alebo ich vymazanie alebo obmedzenie spracúvania, alebo práva namietať proti takémuto spracúvaniu;&nbsp;</p>

        <p>f) právo podať sťažnosť dozornému orgánu;&nbsp;</p>

        <p>g) ak sa osobné údaje nezískali od dotknutej osoby, akékoľvek dostupné informácie, pokiaľ ide o ich zdroj;&nbsp;</p>

        <p><strong>Formuláre žiadostí pre uplatnenie práv dotknutých osôb (DO)</strong></p>

        <p><a href="/gdpr/1-pravo-na-vymaz-ou-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">1. právo na výmaz osobných údajov</a></p>

        <p><a href="/gdpr/2-pravo-na-vylucenie-z-automa-sprac-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">2. právo na vylúčenie z automatického spracovania</a></p>

        <p><a href="/gdpr/3-pravo-namietat-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">3. právo namietať spracúvanie osobných údajov</a></p>

        <p><a href="/gdpr/4-pravo-na-obmedzenie-spracuvania-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">4. právo na obmedzenie spracúvania osobných údajov</a></p>

        <p><a href="/gdpr/5-pravo-na-opravu-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">5. právo na opravu osobných údajov</a></p>

        <p><a href="/gdpr/6-pravo-na-prenosnost-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">6. právo na prenosnosť osobných údajov</a></p>

        <p><a href="/gdpr/7-pravo-na-pristup-formular-ziadosti-na-uplatnenie-prav-dopdf.pdf" rel="noopener noreferrer" target="_blank">7. právo na prístup k osobných údajov</a></p>

      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
// import Navigation from '../components/Navigation.vue';

export default {
  name: 'Gdpr',
  components: {
    // Navigation,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
  @import "~bulma/sass/utilities/initial-variables.sass";
  @import "@/assets/scss/_variables.scss";
  @import "~bulma/bulma";
  @import "@/assets/scss/_home.scss";
</style>
