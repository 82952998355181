<template>
  <div>
    <div class="has-text-centered">
      <b-button
        class="btn"
        type="is-default"
        @click="geoCheck"
      >
        {{button.title}}
      </b-button>
    </div>

    <transition name="fade">
      <div
        v-if="modal"
        class="modal gps is-active"
      >
        <div
          class="modal-background"
          @click="modal=false"
        >
        </div>
        <div class="modal-content" v-if="geo">
          <div class="has-text-centered">
            <h1 class="is-size-5 is-uppercase has-text-yellow has-text-weight-black">
              Tvoja vzdialenosť
            </h1>
            <div>
              <b-loading :is-full-page="false"></b-loading>
              <div class="is-size-2 is-thin has-text-cream" v-if="location">
                <span class="distance">{{distance}}</span> metrov
              </div>
              <div class="has-text-cream" v-else>
                <span class="loading">
                  Škriatkovia zisťujú tvoju polohu. <br>
                  <span>{{timeOuter}}</span>
                </span>
              </div>
            </div> <br>
            <img src="/images/popup.png" alt="popup">
            <p class="has-text-cream mt-6">
              Dostav sa k Mestskej veži.
            </p>

            <div v-if="timeOut && !location">
              <strong class="has-text-cream">
                GPS škriatkovia nevedia nájsť tvoju polohu.
              </strong><br> <br>
              <b-button
                class="btn"
                @click="nextPage()"
              >
                Som na mieste
              </b-button>
            </div>
          </div>

        </div>
        <div class="modal-content" v-if="!geo">
          <div class="has-text-centered">
            <h1 class="is-size-2 has-text-yellow mb-6">
              Technický škriatok zaúradoval.
            </h1>
            <p class="has-text-cream">
              GPS škriatkom sa nepodarilo získať tvoju polohu.
            </p> <br>
            <b-button
              class="btn"
              tag="router-link"
              to="/settings"
            >
              Ako nastaviť GPS
            </b-button>
            <br>
            <b-button
              class="btn"
              @click="nextPage()"
            >
              Som na mieste
            </b-button>
          </div>
        </div>

        <button class="modal-close is-large" aria-label="close" @click="modal=false"></button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GeoButton',
  data() {
    return {
      modal: false,
      location: null,
      distance: 1000,
      gettingLocation: false,
      errorStr: null,
      geoID: null,
      geo: true,
      timeOut: false,
      timeOuter: 10,
    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    if (!('geolocation' in navigator)) {
      this.geo = false;
      return;
    }
    this.gettingLocation = true;
    this.geoID = navigator.geolocation.watchPosition((pos) => {
      this.gettingLocation = false;
      this.location = pos;
      this.distance = this.calculateDistance(
        pos.coords.latitude,
        pos.coords.longitude,
        this.button.lat,
        this.button.lon,
      );
    }, (err) => {
      this.geo = false;
      this.gettingLocation = false;
      this.errorStr = err.message;
    });
  },
  watch: {
    distance() {
      this.checkDistance();
    },
  },
  methods: {
    nextPage() {
      navigator.geolocation.clearWatch(this.geoID);
      this.$emit('place-visited');
    },
    checkDistance() {
      if (this.distance < 50 && this.modal) {
        this.nextPage();
      }
    },
    geoCheck() {
      this.timeOut = false;
      this.timeOuter = 10;
      const interval = setInterval(() => {
        if (!this.location) {
          this.timeOuter -= 1;
        }
      }, 1000);
      setTimeout(() => {
        if (!this.location) {
          this.timeOut = true;
        }
        clearInterval(interval);
      }, 10000);

      this.modal = true;
      this.checkDistance();
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const p = 0.017453292519943295; // Math.PI / 180
      const c = Math.cos;
      const a = 0.5
    - c((lat2 - lat1) * p) / 2
    + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
      return Math.round(1000 * 12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
