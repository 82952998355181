<template>
  <div>
    <div class="fullscreen-bg ">
        <video autoplay muted loop playsinline class="fullscreen-bg__video">
            <source :src="backendStorage+task.image" :key="task.image">
        </video>
    </div>
    <div v-for="button in task.buttons" :key="button.title">
      <div class="has-text-centered fixed-bottom">
        <b-button
          v-if="button.type === 'navigation'"
          class="btn"
          type="is-default"
          v-html="button.title"
          @click="nextTask()"
        >
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationPage',
  props: {
    task: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    backendStorage() {
      return this.$store.getters.backendStorage;
    },
  },
  methods: {
    nextTask() {
      this.$emit('nextTask');
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.fullscreen-bg__video {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.fixed-bottom {
  position: fixed;
  bottom: 0.5rem;
  left: calc(50% - 100px);
  width: 200px;
  margin: 0 auto;
  z-index:1;
}
</style>
