<template>
  <div class="home has-text-homeblack is-family-secondary">
    <Navigation></Navigation>
    <section class="section home-section-padding">
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <img src="/images/vokan.png" alt="Skriatkove kuzlo">
          </div>
          <div class="column">
              <h2 class="is-size-1 is-family-primary has-text-homered has-text-weight-bold">
                GPS škriatok
              </h2>
              <div class="columns mt-6">
                <div class="column is-offset-2 content">
                  <p>
                    <strong>
                      Ak ti začal v úvode hry vystrájať GPS škriatok,
                      tu nájdeš tipy, ako si poradíš.
                    </strong> <br>
                    <strong>Som pri Mestskej veži, ale hra nepokračuje</strong>
                    Ak ste sa dostavili na správne miesto,
                    ale hra stále ukazuje, že vám chýbajú metre,
                    skúste jeden z nasledujúcich tipov:
                    <ul>
                      <li>Skúste obnoviť stránku</li>
                      <li>Vypnite režim šetrenia baterky</li>
                      <li>Reštartujte prehliadač - prípadne skúste iný prehliadač</li>
                      <li>Reštartujte telefón</li>
                      <li>Aktualizujte prehliadač a telefón na najnovšiu verziu</li>
                      <li>
                        Ak žiadny spôsob nepomohol,
                        vypnite si lokalizačné (GPS) služby
                      </li>
                    </ul>

                    <h2>Ako povoliť GPS polohu</h2>
                    <h3>iPhone/iPad zariadenia</h3>

                    <h4>
                      Zapnuté lokalizačné služby
                    </h4>
                    <ol>
                      <li>Kliknite na aplikáciu nastavenia</li>
                      <li>Vyberte súkromie</li>
                      <li>Vyberte lokalizačné služby</li>
                      <li>Skontrolujte či sú lokalizačné služby povolené</li>
                    </ol>
                    <h4>
                      Lokalizačné služby pre prehliadač
                    </h4>
                    <ol>
                      <li>Kliknite na aplikáciu nastavenia</li>
                      <li>Vyberte prehliadač, ktorý používate</li>
                      <li>Pozrite či máte povolené lokalizačné služby pre tvoj prehliadač</li>
                    </ol>
                    <h3>Android zariadenia</h3>

                    <h4>
                      Chrome
                    </h4>

                    <ol>
                      <li>Otvorte aplikáciu Chrome</li>
                      <li>
                        V pravom rohu, kde píšete adresu
                        ťuknite na Viac (tri bodky) a vyberte nastavenia (settings)
                      </li>
                      <li>Vyberte nastavenie stránky (Site settings)
                        a potom Poloha/Lokalizácia (Location)
                      </li>
                      <li>Zapnite polohu a obnovte stránku</li>
                      <li>
                        Pre zmenu nastavení pre konkrétnu stránku,
                        vyberte názov stránky zo zoznamu nižšie
                      </li>
                    </ol>
                </div>
              </div>
          </div>
        </div>
      </div>

    </section>
    <section class="section time">
      <div class="container has-text-centered">
        <p class="flower is-family-secondary">
          <strong class="is-size-4 is-uppercase">
            Hru Škriatkovo kúzlo si môžeš v meste zahrať každý deň od 8:00 do 21:00.
          </strong>
          <br>
          <span class="has-text-weigh-light">
            Na konci hry zistíš, aký je tvoj rodinný škriatok, aj ako s ním udržiavať dobré vzťahy.
          </span>
        </p>
      </div>

    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import Navigation from '../components/Navigation.vue';

export default {
  name: 'Settings',
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
  @import "~bulma/sass/utilities/initial-variables.sass";
  @import "@/assets/scss/_variables.scss";
  @import "~bulma/bulma";
  @import "@/assets/scss/_home.scss";
</style>
