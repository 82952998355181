<!-- eslint-disable max-len -->
<template>
  <div class="home has-text-homeblack is-family-secondary">
    <!-- <Navigation></Navigation> -->
    <section class="section">
      <div class="container content">
        <h1>Ochrana osobných údajov</h1>
        <p><strong>Informačná povinnosť prevádzkovateľa</strong></p>

        <p><strong>Informovanie dotknutých osôb o spracúvaní&nbsp;</strong></p>

        <p><strong>a poskytovaní osobných údajov v spracovateľských operáciách&nbsp;</strong></p>

        <p>podľa článku 13 a článku 14 Nariadenia Európskeho parlamentu a Radu (EÚ) 2016/679 z 27. apríla 2016 &nbsp;o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES &nbsp;</p>

        <p>Nariadenie Európskeho parlamentu a Radu (EÚ) 2016/679 z 27. apríla 2016 &nbsp;o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES stanovuje povinnosť prevádzkovateľa poskytnúť dotknutej osobe informácie o účele spracovania osobných údajov, na ktorý sú jej osobné údaje určené a to aj v prípade, keď sa osobné údaje nezískavajú priamo od dotknutej osoby. Je potrebné, aby tieto informácie boli dotknutej osobe poskytnuté najneskôr pri získavaní jej osobných údajov, respektíve v dostatočnom časovom predstihu, jasne a zrozumiteľne a takým spôsobom, aby sa s týmito informáciami mohla skutočne oboznámiť a porozumela im.&nbsp;</p>

        <p><strong>Prevádzkovateľ: &nbsp; &nbsp; Zaži v Trnave – Mestské kultúrne stredisko, p. o.</strong></p>

        <p><strong>Adresa: &nbsp; &nbsp; &nbsp; &nbsp;Hlavná 1/1, 917 71 Trnava</strong></p>

        <p><strong>Štatutár: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong>Mgr. Peter Cagala, riaditeľ organizácie</p>

        <p><strong>Zodpovedná osoba: &nbsp; &nbsp;</strong>Mgr. Terézia Krupčíková &nbsp;</p>

        <p>e-mail: <a href="mailto:zodpovedna.osoba@kultura.trnava.sk">zodpovedna.osoba@kultura.trnava.sk</a> &nbsp;</p>

        <p>
          <br>
        </p>

        <p><strong>Kontaktné údaje Úradu na ochranu osobných údajov:</strong></p>

        <p>Adresa: &nbsp; &nbsp; &nbsp; &nbsp;Úrad na ochranu osobných údajov Slovenskej republiky</p>

        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Hraničná 12</p>

        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 820 07 Bratislava 27</p>

        <p>IČO: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;36064220</p>

        <p>Telefonické konzultácie v&nbsp;oblasti ochrany osobných údajov:</p>

        <p>Utorok a štvrtok 8:00 – 15:00 +421 2 323 132 20 &nbsp; &nbsp;</p>

        <p>e-mail: <a href="mailto:statny.dozor@pdp.gov.sk">statny.dozor@pdp.gov.sk</a>&nbsp;</p>

        <p>
          <br>
        </p>

        <p><a href="/gdpr/1-zoznam-okruhov-spracovatelskych-operaciiallosopdf.pdf" rel="noopener noreferrer" target="_blank"><strong>1. Zoznam okruhov spracovateľských operácií</strong></a></p>

        <p><a href="/gdpr/2-prehlasenie-o-ochrane-osobnych-udajovpdf.pdf" rel="noopener noreferrer" target="_blank"><strong>2. Prehlásenie o ochrane osobných údajov</strong></a></p>

        <p><a href="/gdpr/3-pouzivanie-suborov-cookiespdf.pdf" rel="noopener noreferrer" target="_blank"><strong>3. Používanie súborov cookies</strong></a></p>

        <p><a href="/gdpr/4-oboznamenie-k-spracuvaniu-osobnych-udajovwebpdf.pdf" rel="noopener noreferrer" target="_blank"><strong>4. Oboznámenie k spracúvaniu osobných údajov</strong></a></p>

        <p><a href="/#/poucenie-o-pravach-dotknutych-osob"><strong>5. Uplatnenie práv dotknutých osôb</strong></a></p>

        <p><a href="/gdpr/6.%20Zaznamy%20o%20spracovatelskych%20cinnostiach_PDF.pdf" rel="noopener noreferrer" target="_blank"><strong>6. Záznamy o spracovateľských činnostiach</strong></a></p>

      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
// import Navigation from '../components/Navigation.vue';

export default {
  name: 'Gdpr',
  components: {
    // Navigation,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
  @import "~bulma/sass/utilities/initial-variables.sass";
  @import "@/assets/scss/_variables.scss";
  @import "~bulma/bulma";
  @import "@/assets/scss/_home.scss";
</style>
