<template>
  <div>
    <form @submit.prevent="checkAnswer" class="has-text-centered">
      <div class="field">
        <label class="label is-uppercase has-text-redblack is-size-6">{{button.title}}</label>
        <div class="control flower">
          <input
            class="input-number "
            type="number"
            placeholder="Sem napíš číslo..."
            v-model="value"
          >
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button class="button btn">Odoslať</button>
        </div>
      </div>
    </form>
    <transition name="roll" mode="out-in" appear>
      <div class="notification notification-red" v-if="wrongAnswer">
        <button
          class="delete"
          @click="hideNotification()">
        </button>
        Pôvabný výsledok, ale nesprávny.
        Skús spočítať "ô" ešte raz.
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Input',
  data() {
    return {
      value: '',
      wrongAnswer: false,
    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    hideNotification() {
      this.wrongAnswer = false;
    },
    checkAnswer() {
      if (this.value === this.button.value) {
        this.$emit('right-answer');
      } else {
        this.wrongAnswer = true;
      }
    },
  },
};
</script>
