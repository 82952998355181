<template>
  <div>
    <div class="has-text-centered">
      <b-button
        v-if="button.type==='action'"
        v-bind:key="button.title"
        class="btn-action"
        v-bind:class="{ 'is-loading': actionLoading }"
        :disabled = "countDown !== 0 "
        native-type="submit"
        type="is-default"
        @click="runAction(button.value)"
      >
        <template v-if="countDown">
          <span class="is-size-2">
            {{countDown}} s
          </span>
        </template>
        <template v-else>
          <span class="is-uppercase">
            {{button.title}}
          </span>
        </template>
      </b-button>
    </div>

    <transition name="roll">
      <div class="notification notification-green" v-if="actionActivated">
        <button
          class="delete"
          @click="hideNotification('actionActivated')">
        </button>
        Sleduj kúzlo za oknom.
      </div>
      <div class="notification notification-yellow" v-if="actionLocked">
        <button
          class="delete"
          @click="hideNotification('actionLocked')">
        </button>
        Počkaj kým kúzlo za oknom skončí a pusti si ho zas.
      </div>
      <div class="notification notification-red" v-if="actionLockedByUser">
        <button
          class="delete"
          @click="hideNotification('actionLockedByUser')">
        </button>
        Nechaj kúzlo oddychnúť, je unavené.
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  data() {
    return {
      countDown: 0,
      actionActivated: false,
      actionLocked: false,
      actionLockedByUser: false,
      actionLoading: false,
    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    hideNotification(action) {
      if (action === 'actionActivated') this.actionActivated = false;
      if (action === 'actionLocked') this.actionLocked = false;
      if (action === 'actionLockedByUser') this.actionLockedByUser = false;
    },
    timer(time) {
      this.countDown = time;
      const counter = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown <= 0) {
          this.actionActivated = false;
          this.actionLocked = false;
          this.actionLockedByUser = false;
          clearInterval(counter);
        }
      }, 1000);
    },
    runAction(action) {
      this.actionLoading = true;
      const data = new URLSearchParams();
      data.append('email', localStorage.email);
      data.append('pin', localStorage.pin);
      data.append('action', action);
      this.$store.dispatch('runAction', data).then((response) => {
        if (action === '3') {
          this.$emit('nextTask');
        }
        this.actionLoading = false;
        this.actionActivated = true;
        this.timer(response.data.time);
      }, (error) => {
        console.log(error.response.data);
        if (error.response.data.error === 'user-locked') this.actionLockedByUser = true;
        if (error.response.data.error === 'action-locked') this.actionLocked = true;
        this.actionLoading = false;
        this.timer(error.response.data.time);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .fade-leave-to {
    opacity: 0;
  }
</style>
