<template>
  <div v-if="task" :class="'game game'+task.id">
    <div id="only-mobile" class="has-text-black content">
      <h1>Iba na mobile</h1>
      <p>
        Hru je možné hrať iba na mobilnom zariadení.
      </p>
      <br>
      <router-link
        class="button btn"
        :to="{name:'Play'}"
      >
      Späť
      </router-link>
    </div>
    <section class="section">
      <transition name="fade" mode="out-in" appear>
        <div class="container"  :key="task.id">

          <DwarfPage
            v-if="task.type==='surprise'"
            :key="task.id"
            :task="task"
            @nextTask="nextTask"
            @playVoice="playVoice"
          >
          </DwarfPage>

          <div v-else>
            <div
              v-if="task.type!='navigation'"
              class="has-text-centered mb-5"
            >
              <img v-if="task.image" :src="backendStorage+task.image" :key="task.image">
            </div>

            <div
              v-if="task.type==='convert'"
              class="has-text-centered content"
              :key="task.id"
            >
              <h3>ŠKRIATKOVSKÝ PREVODNÍK KROKOV</h3>

              <p>
                Tvoja topánka spravila cestou <br>
                <span class="is-size-1 is-black">{{steps*5}}</span><br>
                škriatkovských krokov.
              </p>
            </div>

            <div
              class="content"
              v-html="task.description"
              :key="task.description"
              style="transition-delay: 0.2s">
            </div>
          </div>

          <QuizPage
            v-if="task.type==='quiz'"
            :key="task.id"
            :buttons="task.buttons"
            @right-answer="nextTask"
          />

          <DrawPage
            v-if="task.type==='draw'"
            :key="task.id"
            :buttons="task.buttons"
            @complete-game="nextTask"
          />

          <NavigationPage
            v-if="task.type==='navigation'"
            :key="task.id"
            :task="task"
            @nextTask="nextTask"
          />

          <template
            v-for="button in task.buttons"
          >
            <div
              v-if="button.type==='next'"
              class="has-text-centered"
              v-bind:key="button.title"
            >
              <b-button
                v-html="button.title"
                class="btn"
                native-type="submit"
                type="is-default"
                @click="nextTask"
              >
              </b-button>
            </div>
            <div
              v-if="button.type==='newgame'"
              class="has-text-centered"
              v-bind:key="button.title"
            >
              <b-button
                v-html="button.title"
                class="btn"
                native-type="submit"
                type="is-default"
                @click="newGame"
              >
              </b-button>
            </div>
            <div
              v-if="button.type==='counter'"
              class="has-text-centered "
              v-bind:key="button.title"
            >
              <b-button
                class="btn-shoe has-text-left"
                native-type="submit"
                type="is-default"
                @click="stepCount"
              >
                <span class="is-size-1">{{steps}}</span><br>POČÍTAJ<br>KROKY
              </b-button>
            </div>

            <ActionButton
              v-if="button.type==='action'"
              :key="button.title"
              :button="button"
              @nextTask="nextTask"
            />

            <SnowButton
              v-if="button.type==='snow'"
              :key="button.title"
              :button="button"
              @animationEnded="nextTask"
              @animationStart="stopSound"
            />

            <SoundButton
              v-if="button.type==='sound'"
              :key="button.title"
              :button="button"
              @playSound="playSound"
            />

            <GeoButton
              v-if="button.type==='geo'"
              :key="button.title"
              :button="button"
              @place-visited="nextTask"
            />

            <DragButton
              v-if="button.type==='drag'"
              :key="button.title"
              :button="button"
              @solved="nextTask"
            />

            <Input
              v-if="button.type==='input'"
              :key="button.title"
              :button="button"
              @right-answer="nextTask"
            />

          </template>

        </div>
      </transition>
    </section>

    <section class="footer-stick">
      <div class="columns is-mobile">
        <div class="column">
          <b-button
            v-if="task && task.sort_order > 0"
            class="back-button"
            type="is-default"
            @click="backTask"
          >
            <img src="/images/back.png">
          </b-button>
        </div>
        <div class="column has-text-right">
          <b-button
            class="sound-button"
            type="is-default"
            @click="soundSet"
          >
            <img src="/images/sound.png" alt="" v-if="voice && voice.playing()">
            <img src="/images/soundstop.png" alt="" v-if="!muted && !voice">
            <img src="/images/soundnot.png" alt="" v-if="muted">
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Howl } from 'howler';
import QuizPage from '@/components/QuizPage.vue';
import DrawPage from '@/components/DrawPage.vue';
import NavigationPage from '@/components/NavigationPage.vue';
import ActionButton from '@/components/ActionButton.vue';
import SnowButton from '@/components/SnowButton.vue';
import SoundButton from '@/components/SoundButton.vue';
import DragButton from '@/components/DragButton.vue';
import GeoButton from '@/components/GeoButton.vue';
import Input from '@/components/Input.vue';
import DwarfPage from '../components/DwarfPage.vue';

export default {

  name: 'Game',
  data() {
    return {
      mobile: window.innerWidth < 760,
      steps: 0,
      musicLoaded: false,
      voice: null,
      sound: null,
      muted: false,
      playing: false,
    };
  },
  components: {
    QuizPage,
    DrawPage,
    NavigationPage,
    ActionButton,
    SnowButton,
    SoundButton,
    DragButton,
    GeoButton,
    Input,
    DwarfPage,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    task() {
      return this.$store.getters.task;
    },
    backendStorage() {
      return this.$store.getters.backendStorage;
    },
    audioStorage() {
      return this.$store.getters.audioStorage;
    },
  },
  mounted() {
    const htmlElement = document.documentElement;
    htmlElement.classList.add('html-game');
    if (!this.user) {
      if (!localStorage.email) {
        this.$router.push({ name: 'Home' });
      } else {
        const user = new URLSearchParams();
        user.append('email', localStorage.email);
        user.append('pin', localStorage.pin);
        this.$store.dispatch('setTasks').then(() => {
          this.$store.dispatch('loginUser', user).then(() => {
            this.$store.dispatch('setTask');
          });
        });
      }
    } else {
      this.$store.dispatch('setTasks').then(() => {
        this.$store.dispatch('setTask');
      });
    }
  },
  watch: {
    task() {
      if (this.mobile) {
        this.$gtag.pageview({
          page_title: `${this.task.sort_order}. ${this.task.title}`,
          page_path: `${this.task.sort_order}.html`,
        });
        this.playVoice();
        this.$scrollTo('body', 500);
      }
    },
  },
  created() {
    if (localStorage.muted === 'true') {
      this.muted = true;
    }
  },
  methods: {
    setMute(muted) {
      this.muted = muted;
      localStorage.muted = muted;
    },
    soundSet() {
      if (!this.voice) {
        if (localStorage.muted) {
          this.setMute(false);
        }
        this.playVoice();
      } else {
        this.voice.stop();
        this.voice.unload();
        this.voice = null;
        this.setMute(true);
      }
    },
    stopSound() {
      if (!this.muted) {
        if (this.voice) {
          this.voice.fade(1, 0, 1000);
          this.voice.once('fade', () => {
            this.voice.unload();
            this.voice = null;
          });
        }
      }
    },
    playVoice(source = false) {
      let audioSource = this.audioStorage + this.task.voice;
      if (source) audioSource = source;
      if (!this.muted) {
        if (!this.voice) {
          this.voice = new Howl({
            src: [audioSource],
          });
          this.voice.play();
          this.voice.once('end', () => {
            this.voice.unload();
            this.voice = null;
          });
        } else {
          this.voice.fade(1, 0, 1000);
          this.voice.once('fade', () => {
            this.voice.unload();
            this.voice = null;
            this.voice = new Howl({
              src: [audioSource],
            });
            this.voice.play();
            this.voice.once('end', () => {
              this.voice.unload();
              this.voice = null;
            });
          });
        }
      }
    },
    playSound(sound) {
      this.sound = new Howl({
        src: [`${this.audioStorage}/audio/${sound}.mp3`],
      });
      this.sound.play();
    },
    backTask() {
      this.$store.dispatch('previousTask');
    },
    nextTask() {
      this.$store.dispatch('nextTask');
    },
    newGame() {
      this.$store.dispatch('newGame');
    },
    stepCount() {
      this.steps += 1;
      const number = Math.floor(Math.random() * Math.floor(10)) + 1;
      this.playSound(number);
    },
  },
};
</script>

<style lang="scss">
@import "~bulma/sass/utilities/initial-variables.sass";
@import "@/assets/scss/_variables.scss";
@import "~bulma/bulma";
@import "@/assets/scss/_basic.scss";
@import "@/assets/scss/_home.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.game {
  margin-bottom:20vh;
}
</style>
