<template>
  <div class="has-text-centered mt-6">
    <canvas id="drawing" width="380" height="380"></canvas>
    <br>
    <transition name="fade">
      <b-button
        v-if="!good"
        class="btn has-text-weight-black"
        type="is-default"
        @click="drawNew()"
      >
      Skús znovu
      </b-button>
      <b-button
        v-if="completeDots"
        class="btn has-text-weight-black"
        @click="completeGame()"
      >
      Super hotovo
      </b-button>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DrawPage',
  data() {
    return {
      good: true,
      completeDots: false,
      lastDot: null,
      canvas: null,
      ctx: null,
      dots: [
        {
          o: 1, x: 168, y: 347, r: 10,
        },
        {
          o: 2, x: 126, y: 347, r: 10,
        },
        {
          o: 3, x: 108, y: 212, r: 10,
        },
        {
          o: 4, x: 24, y: 252, r: 10,
        },
        {
          o: 5, x: 200, y: 61, r: 10,
        },
        {
          o: 6, x: 231, y: 101, r: 10,
        },
        {
          o: 7, x: 234, y: 25, r: 10,
        },
        {
          o: 8, x: 290, y: 26, r: 10,
        },
        {
          o: 9, x: 258, y: 132, r: 10,
        },
        {
          o: 10, x: 341, y: 235, r: 10,
        },
        {
          o: 11, x: 265, y: 216, r: 10,
        },
        {
          o: 12, x: 259, y: 344, r: 10,
        },
      ],
    };
  },
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.prepCanvas();
    this.drawDots();
    this.canvas.addEventListener('touchstart', (e) => {
      if (!this.completeDots) {
        this.checkForDot(e);
      }
    });
  },
  methods: {
    circleCollision(c1, c2) {
      const a = c1.r + c2.r;
      const x = c1.x - c2.x;
      const y = c1.y - c2.y;

      if (a > Math.sqrt((x * x) + (y * y))) return true;
      return false;
    },
    drawDots() {
      // this.dots.forEach((dot) => {
      //   this.ctx.beginPath();
      //   this.ctx.arc(dot.x, dot.y, dot.r, 0, 2 * Math.PI);
      //   this.ctx.fillStyle = '#000';
      //   this.ctx.fill();
      //   this.ctx.closePath();
      // });
    },
    prepCanvas() {
      // const res = window.devicePixelRatio || 1;
      // const scale = 1 / res;
      this.canvas = document.getElementById('drawing');
      this.ctx = this.canvas.getContext('2d');
    },
    checkForDot(e) {
      let collisionDot = null;
      const rect = this.canvas.getBoundingClientRect();
      console.log(rect);
      const scale = 380 / rect.width;
      const eventX = (e.targetTouches[0].pageX - rect.left) * scale;
      const eventY = (e.targetTouches[0].pageY - rect.top) * scale;
      const touch = { x: eventX, y: eventY, r: 20 };
      console.log(touch);
      this.dots.forEach((dot) => {
        if (this.circleCollision(dot, touch)) collisionDot = dot;
      });
      // AK KLIKOL NA BOD
      if (collisionDot !== null) {
        // AK NIE JE PRVY TEN BOD
        if (this.lastDot !== null) {
          this.drawLine(collisionDot);
          // AK PREDCHADZAJUCI JE SPRAVNY
          if (this.lastDot.o !== collisionDot.o - 1) {
            this.good = false;
          }
          // AK POSLEDNY JE SPRAVNY KONIEC HRY
          if (collisionDot.o === 12 && this.good) {
            this.completeDots = true;
          }
        } else if (collisionDot.o !== 1) {
          this.good = false;
        }
        this.lastDot = collisionDot;
      }
    },
    drawNew() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.good = true;
      this.drawDots();
      this.lastDot = null;
    },
    drawLine(toDot) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.lastDot.x, this.lastDot.y);
      this.ctx.lineTo(toDot.x, toDot.y);
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = '#2D0C0D';
      this.ctx.stroke();
      this.ctx.closePath();
    },
    completeGame() {
      this.$emit('complete-game');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#drawing {
  background-image: url('/images/drawing.png');
  background-repeat: no-repeat;
  background-size: contain;
  width:100%;
  max-width: 380px;
}
</style>
