<template>
  <div class="has-text-centered is-inline">
    <b-button
      v-bind:key="button.title"
      :class="'btn '+button.value"
      type="is-default"
      @click="playSound(button.value)"
    >
      {{button.title}}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'SoundButton',
  data() {
    return {

    };
  },
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    backendStorage() {
      return this.$store.getters.backendStorage;
    },
  },
  methods: {
    playSound(sound) {
      this.$emit('playSound', sound);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background-repeat: no-repeat;
  background-position: center top;
  width: 50%;
  z-index: 3;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.btn.piano{
  background-image: url('/images/piano.png');
  padding-top:170px;
}
.btn.strings{
  background-image: url('/images/strings.png');
  padding-top:170px;
}
.btn.harph{
  background-image: url('/images/harph.png');
  padding-top:180px;
}
.btn.xylophone{
  background-image: url('/images/xylophone.png');
  padding-top:180px;
}
</style>
