<template>
  <div class="home has-text-homeblack is-family-secondary">
    <transition name="roll">
      <div class="notification notification-green" v-if="emailSended">
        <button
          class="delete"
          @click="hideNotification('emailSended')">
        </button>
        Email s PIN kódom je už na ceste. Skontroluj tvoju emailovú schránku.
      </div>
      <div class="notification notification-red" v-if="emailSendFail">
        <button
          class="delete"
          @click="hideNotification('emailSendFail')">
        </button>
        Mrzí nás to, ale hra Škriatkove kúzlo teraz chvíľu nefunguje. <br>
        Technický škriatok niekde vystrája huncútstva. <br>
        Snažíme sa ho čo najrýchlejšie nájsť.
        Ďakujeme ti za trpezlivosť a tešíme sa na teba o chvíľu. <br>
        Tvoji škriatkovia
      </div>
      <div class="notification notification-red" v-if="loginError">
        <button
          class="delete"
          @click="hideNotification('loginError')">
        </button>
        Zadaný email, alebo PIN kód nie je správny. Skontroluj údaje.
      </div>
    </transition>
    <Navigation></Navigation>
    <section class="section">
      <div class="container">

        <div v-if="continueGame" class="has-text-centered">
          Naši škriatkovia zistili,
          že si sa už prihlásil/a a môžeš pokračovať s týmto emailom: {{continueEmail}}
          <br>
          <b-button
            class="btn mt-4 mb-6"
            type="is-default"
            tag="router-link"
            :to="{name:'Game'}"
          >
            Pokračovať v hre
          </b-button>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <h2
              class="is-uppercase
              is-size-3
              has-text-weight-light"
            >
              <strong>Registrácia</strong>
            </h2>
            <h1 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Idem hrať prvýkrát
            </h1>

            <p class="mt-6">
              Pre spustenie hry potrebuješ PIN kód, ktorý ti naši škriatkovia pošlú na email.
            </p>

            <form @submit.prevent="registerUser" class="mt-4 mb-6">
              <div class="field">
                <label class="label">Zadaj email</label>
                <div class="control">
                  <input class="input" type="email" placeholder="Tvoj Email" v-model="emailReg">
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" required>
                    Súhlasím so
                    <a href="/pdf/gdpr.pdf" target="_blank">spracovaním osobných údajov</a>
                  </label>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button btn">Poslať PIN</button>
                </div>
              </div>
            </form>

          </div>
          <div class="column is-5 is-offset-1">
            <h2
              class="is-uppercase
              is-size-3
              has-text-weight-light"
            >
              <strong>Prihlásenie</strong>
            </h2>
            <h1 class="is-size-1 has-text-homered is-family-primary has-text-weight-bold">
              Už mám PIN
            </h1>
            <p class="mt-6">
              Ak už ti škriatkovia poslali PIN, zadaj svoj email a PIN a môžeš hrať.
            </p>
            <form @submit.prevent="loginUser" class="mt-4">
              <div class="field">
                <label class="label">Zadaj email</label>
                <div class="control">
                  <input
                    class="input"
                    required
                    type="email"
                    placeholder="Tvoj Email"
                    v-model="email"
                  >
                </div>
              </div>
              <div class="field">
                <label class="label">PIN kód</label>
                <div class="control">
                  <input
                    class="input"
                    required
                    type="number"
                    placeholder="Tvoj PIN z emailu"
                    v-model="pin"
                  >
                </div>
                <p class="help is-danger" v-if="loginError">Zlý PIN kód, alebo email</p>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button btn">Hrať</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import Navigation from '../components/Navigation.vue';

export default {
  name: 'RealHome',
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      loginError: false,
      emailSended: false,
      emailSendFail: false,
      continueGame: false,
      continueEmail: false,
      emailReg: '',
      email: '',
      pin: '',
      sended: true,
    };
  },
  mounted() {
    if (localStorage.email && localStorage.pin) {
      this.continueGame = true;
      this.continueEmail = localStorage.email;
    }
  },
  methods: {
    hideNotification(action) {
      this[action] = false;
    },
    registerUser() {
      const user = new URLSearchParams();
      user.append('email', this.emailReg);
      this.$store.dispatch('registerUser', user).then(() => {
        this.emailSended = true;
        this.emailSendFail = false;
      }, (error) => {
        this.emailSendFail = true;
        this.emailSended = false;
        console.log(error.response.data);
      });
    },
    loginUser() {
      const user = new URLSearchParams();
      user.append('email', this.email);
      user.append('pin', this.pin);
      this.$store.dispatch('loginUser', user).then(() => {
        this.$router.push('Game');
      }, (error) => {
        this.loginError = true;
        console.log(error.response.data);
      });
    },
  },
};
</script>
