<template>
  <div>
    <div
      class="content"
      v-html="task.description"
      :key="task.description"
      style="transition-delay: 0.2s">
    </div>
    <img
      v-if="task.image"
      :src="backendStorage+task.image"
      :key="task.image"
      @click="showDwarf"
    >
    <transition name="fade">
      <div
        v-if="modal"
        class="modal gps is-active"
      >
        <div
          class="modal-background"
          @click="modal=false"
        >
        </div>
        <div class="modal-content no-max has-text-centered">
          <section class="section">
            <div class="container">
              <h2 class="mt-6 has-text-cream is-uppercase">Zoznám sa, toto je</h2>
              <h1 class="is-size-2 is-uppercase has-text-yellow has-text-weight-black">
                {{user.dwarf.name}}
              </h1>
              <img :src="backendStorage+user.dwarf.photo">
                <div
                  class="has-text-left has-text-cream"
                  v-html="user.dwarf.description">
                </div>
              <br>
              <img src="/images/popup.png" alt="popup">
              <div v-for="button in task.buttons" :key="button.title">
                <div class="has-text-centered fixed-bottom">
                  <b-button
                    class="btn"
                    type="is-default"
                    v-html="button.title"
                    @click="nextTask()"
                  >
                  </b-button>
                </div>
              </div>
            </div>
          </section>

        </div>
        <button class="modal-close is-large" aria-label="close" @click="modal=false"></button>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: 'DwarfPage',
  data() {
    return {
      modal: false,
    };
  },
  props: {
    task: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    backendStorage() {
      return this.$store.getters.backendStorage;
    },
    audioStorage() {
      return this.$store.getters.audioStorage;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showDwarf() {
      console.log(this.user);
      this.$emit('playVoice', this.audioStorage + this.user.dwarf.voiceover);
      this.modal = true;
    },
    nextTask() {
      this.modal = false;
      this.$emit('nextTask');
    },
  },
};
</script>
