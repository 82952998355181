<template>
  <nav class="navbar">
    <div class="container">
      <div class="navbar-brand">
        <router-link :to="{ name: 'RealHome'}" class="navbar-item">
          <img src="/images/logo.png" class="logo" alt="Mesto Trnava">
        </router-link>
        <span class="navbar-burger" data-target="navbarMenuHeroA">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div
        id="navbarMenuHeroA"
        class="navbar-menu  is-uppercase has-text-weight-bold"
      >
        <div class="navbar-end ">
          <router-link :to="{ name: 'Play'}" class="navbar-item">
            Hrať hru
          </router-link>
          <router-link :to="{ name: 'Settings'}" class="navbar-item">
            Pomoc
          </router-link>
          <router-link :to="{ name: 'Recipe'}" class="navbar-item">
            Recept na maslové sušienky
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  mounted() {
    const htmlElement = document.documentElement;
    htmlElement.classList.remove('html-game');

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a{{'\xa0'}}click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const { target } = el.dataset;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  },
};
</script>
