<template>
  <div class="has-text-centered">
    <template>
      <transition name="roll">
        <div class="notification notification-green" v-if="actionActivated">
          <button
            class="delete"
            @click="hideNotification('actionActivated')">
          </button>
          Sleduj kúzlo za oknom.
        </div>
        <div class="notification notification-yellow" v-if="actionLocked">
          <button
            class="delete"
            @click="hideNotification('actionLocked')">
          </button>
          Počkaj kým kúzlo za oknom skončí a pusti si ho zas.
        </div>
        <div class="notification notification-red" v-if="actionLockedByUser">
          <button
            class="delete"
            @click="hideNotification('actionLockedByUser')">
          </button>
          Nechaj kúzlo oddychnúť, je unavené.
        </div>
      </transition>
      <template v-if="countDown">
        <span class="is-size-2">
          {{countDown}}s
        </span>
      </template>
      <div class="mt-6">
        <div class="is-inline " v-for="button in buttons" :key="button.title">
          <b-button
            v-if="button.type === 'switch'"
            class="btn-switch"
            v-bind:class="{
              'is-loading': actionLoading[button.value],
              'btn-switch-on': isOn[button.value],
              'btn-switch-off': !isOn[button.value]
            }"
            native-type="submit"
            :disabled = "disabled"
            type="is-default"
            @click="runSwitch(button.value)"
          >

          </b-button>

          <b-button
            v-if="button.type === 'quiz'"
            class="btn-quiz has-text-weight-black"
            type="is-default"
            v-html="button.title"
            @click="checkAnswer(button.value,button.title)"
          >
          </b-button>

          <b-button
            v-if="button.type === 'day' && button.lat == day"
            class="btn-quiz"
            type="is-default"
            v-html="button.title"
            @click="checkAnswer(button.value,button.title)"
          >
          </b-button>
        </div>
      </div>
      <transition name="roll" mode="out-in" appear>
        <div class="notification notification-red" v-if="wrongAnswer">
          <button
            class="delete"
            @click="hideNotification('wrongAnswer')">
          </button>
          Nesprávna odpoveď. Skús ešte raz.
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
export default {
  name: 'QuizPage',
  data() {
    return {
      day: (new Date().getDay()),
      value: '',
      wrongAnswer: false,
      countDown: 0,
      actionActivated: false,
      actionLocked: false,
      actionLockedByUser: false,
      actionLoading: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      isOn: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      disabled: false,
      timerInterval: null,
    };
  },
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    hideNotification(action) {
      if (action === 'actionActivated') this.actionActivated = false;
      if (action === 'actionLocked') this.actionLocked = false;
      if (action === 'actionLockedByUser') this.actionLockedByUser = false;
      if (action === 'wrongAnswer') this.wrongAnswer = false;
    },
    timer(time) {
      clearInterval(this.timerInterval);
      this.countDown = time;
      this.timerInterval = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown <= 0) {
          this.disabled = false;
          this.actionActivated = false;
          this.actionLocked = false;
          this.actionLockedByUser = false;
          clearInterval(this.timerInterval);
          this.isOn = {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
          };
        }
      }, 1000);
    },
    runSwitch(switchID) {
      this.actionLoading[switchID] = true;
      const data = new URLSearchParams();
      data.append('email', localStorage.email);
      data.append('pin', localStorage.pin);
      data.append('switchID', switchID);
      this.$store.dispatch('runSwitch', data).then((response) => {
        const newValue = !this.isOn[switchID];
        this.isOn = {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
        };
        this.actionLoading[switchID] = false;
        this.isOn[switchID] = newValue;
        this.actionActivated = true;
        this.timer(response.data.time);
        this.disabled = false;
      }, (error) => {
        if (error.response.data.error === 'user-locked') this.actionLockedByUser = true;
        if (error.response.data.error === 'action-locked') this.actionLocked = true;
        this.actionLoading[switchID] = false;
        this.timer(error.response.data.time);
        this.disabled = true;
      });
    },
    checkAnswer(value, question) {
      this.$gtag.event('quiz', { answer: question });
      if (value === '1') {
        this.$emit('right-answer');
      } else {
        this.wrongAnswer = true;
      }
    },
  },
};
</script>
