import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tasks: [],
    user: null,
    loginError: false,
    task: null,
    backendUrl: 'https://skriatkovia.trnava.sk/back',
    backendStorage: 'https://skriatkovia.trnava.sk/back/storage/app/media',
    audioStorage: 'https://skriatkovia.trnava.sk/back/storage/app/media',
    // backendUrl: 'http://adventback.test',
    // backendStorage: 'http://adventback.test/storage/app/media',
    // audioStorage: 'http://localhost:8080/',
  },
  getters: {
    allTasks: (state) => state.tasks,
    task: (state) => state.task,
    loginError: (state) => state.loginError,
    user: (state) => state.user,
    backendUrl: (state) => state.backendUrl,
    backendStorage: (state) => state.backendStorage,
    audioStorage: (state) => state.audioStorage,
  },
  mutations: {
    SET_TASKS: (state, tasks) => {
      state.tasks = tasks;
    },
    SET_TASK: (state, task) => {
      state.task = task;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_PROGRESS: (state, progress) => {
      state.user.progress = progress;
    },
    SET_LOGIN_ERROR: (state, error) => {
      state.loginError = error;
    },
  },
  actions: {
    setTasks({ commit, state }) {
      return new Promise((resolve) => {
        axios.get(`${state.backendUrl}/tasks`).then((response) => {
          commit('SET_TASKS', response.data);
          resolve();
        });
      });
    },
    previousTask({ commit, state }) {
      const progress = state.user.progress - 1;
      commit('SET_USER_PROGRESS', progress);
      const filtered = state.tasks.filter((task) => task.sort_order === state.user.progress);
      commit('SET_TASK', filtered[0]);
      const user = new URLSearchParams();
      user.append('email', localStorage.email);
      user.append('pin', localStorage.pin);
      user.append('progress', progress);
      axios.post(`${state.backendUrl}/set-progress`, user);
    },
    newGame({ commit, state }) {
      const progress = 0;
      commit('SET_USER_PROGRESS', progress);
      const filtered = state.tasks.filter((task) => task.sort_order === state.user.progress);
      commit('SET_TASK', filtered[0]);
      const user = new URLSearchParams();
      user.append('email', localStorage.email);
      user.append('pin', localStorage.pin);
      user.append('progress', progress);
      axios.post(`${state.backendUrl}/new-game`, user);
    },
    nextTask({ commit, state }) {
      const progress = state.user.progress + 1;
      commit('SET_USER_PROGRESS', progress);
      const filtered = state.tasks.filter((task) => task.sort_order === state.user.progress);
      commit('SET_TASK', filtered[0]);
      const user = new URLSearchParams();
      user.append('email', localStorage.email);
      user.append('pin', localStorage.pin);
      user.append('progress', progress);
      axios.post(`${state.backendUrl}/set-progress`, user);
    },
    setTask({ commit, state }) {
      const filtered = state.tasks.filter((task) => task.sort_order === state.user.progress);
      commit('SET_TASK', filtered[0]);
    },
    registerUser(context, user) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.state.backendUrl}/register-user`, user).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    loginUser({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.backendUrl}/login-user`, user).then((response) => {
          commit('SET_LOGIN_ERROR', false);
          commit('SET_USER', response.data);
          localStorage.email = response.data.email;
          localStorage.pin = response.data.secret;
          resolve();
        }).catch(() => {
          commit('SET_LOGIN_ERROR', true);
          reject();
        });
      });
    },
    runAction(contex, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${contex.state.backendUrl}/run-action`, data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    runSwitch(contex, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${contex.state.backendUrl}/run-switch`, data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },

  },
  modules: {
  },
});
